<script setup lang="ts">

import {NConfigProvider, NDialogProvider, NMessageProvider} from "naive-ui";

/**
 * Use this for type hints under js file
 * @type import('naive-ui').GlobalThemeOverrides
 */
const themeOverrides: import('naive-ui').GlobalThemeOverrides = {
  Button: {
    borderFocus: '#000',
    borderHover: '#000',
    borderPressed: '#000',
    color: '#FFF',
    colorFocus: '#FFF',
    colorHover: '#d2d2d2',
    colorPressed: '#d2d2d2',
    colorPrimary: '#1D2632',
    colorDisabled: '#FFF',
    textColorHover: '#000',
    textColorFocus: '#000',
    borderFocusPrimary: '#000',
    borderFocusSuccess: '#000',
  },
}

const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
}
</script>

<template>
  <n-config-provider :theme-overrides="themeOverrides" :breakpoints="breakpoints">
    <n-message-provider closable>
      <n-dialog-provider>
        <slot/>
      </n-dialog-provider>
    </n-message-provider>
  </n-config-provider>
</template>

<style>
.n-message-container {
  margin-top: 195px;
}

@media (min-width: 768px) {
  .n-message-container {
    margin-top: 140px;
  }
}

@media (min-width: 1024px) {
  .n-message-container {
    margin-top: 65px;
  }
}
</style>